import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'previousVisits'
})
export class PreviousVisitsPipe implements PipeTransform {

  transform(visits: any): any {
    let now = new Date();
    const oneHourAgo = new Date(now.getTime() - 60 * 60 * 1000);
    return visits.filter((visit: any) => new Date(visit.date) > oneHourAgo && new Date(visit.date) <= new Date(now));
  }

}
