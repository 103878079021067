<div class="bodyContent">
  <h3 class="font-Lato-regular text-xl text-white d-flex align-items-center justify-content-center mb-3">
    <b class="wrapped">DISCOVERY CENTER (MIG Station)</b>
  </h3><br>
  <div class="content">
    <p class="font-Lato-regular text-xs text-white"><b>
        Términos y condiciones del 10 de septiembre de 2024, del Ayuntamiento de Madrid para el Campus del Videojuego de
        Madrid In Game, en los que se fijan las condiciones de acceso al DISCOVERY CENTER.
      </b></p>
    <p class="font-Lato-regular text-xs text-white">
      El Centro Experiencial de Madrid In Game es un recorrido inmersivo en el que el usuario explora el espacio,
      disfrutando de una experiencia a través de distintos soportes y utilizando su smartphone como nexo con dicha
      experiencia. El recorrido se compone de dos áreas/espacios:
    </p>
    <p class="font-Lato-regular text-xs text-white">
      1. <b>La SENDA ESTELAR:</b> recorrido exterior que circunvala todos los edificios del Campus del Videojuego de
      Madrid In
      Game.
    </p>
    <p class="font-Lato-regular text-xs text-white">
      2. <b>El centro experiencial, denominado MIG Station,</b> situado en la planta superior del DISCOVERY CENTER
      (Edificio
      Valencia).
    </p>
    <p class="font-Lato-regular text-xs text-white">
      Para disfrutar de la experiencia, es necesaria una reserva previa y gratuita, que debe realizarse a través de la
      webApp <a href="" class="subrayado">http://discoverycenter.madridingame.es/</a>, de acuerdo con los siguientes
      términos y condiciones:
    </p>
    <hr />
    <p class="font-Lato-regular text-xs text-white">
      <b>Términos y condiciones:</b>
    </p>
    <div class="list-content">
      <p class="font-Lato-regular text-xs text-white">
        1. Se establece que el usuario deberá realizar una “reserva” para su visita al DISCOVERY CENTER-MIG Station a
        través
        de la página web <a href="" class="subrayado">http://discoverycenter.madridingame.es/</a> o descargando la
        aplicación de Madrid In Game, en el
        apartado
        “Descubre”.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        2. La visita consta de dos partes, cuyo recorrido se realiza de forma secuencial. Comienza por la SENDA ESTELAR
        (45
        min) y continúa en la MIG Station, a la cual se asigna un horario de visita mediante la reserva. El acceso a la
        MIG
        Station se dará a la hora indicada en el pase.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        3. El horario de visitas está fijado de la siguiente manera:
      </p>
      <p class="font-Lato-regular text-xs text-white">
        a. MIG Station: [Definir horario].
      </p>
      <p class="font-Lato-regular text-xs text-white">
        b. SENDA ESTELAR: [Definir horario].
      </p>
      <p class="font-Lato-regular text-xs text-white">
        4. La reserva de un “pase gratuito” para el recorrido interior de MIG Station – DISCOVERY CENTER incluye el
        acceso
        a
        la SENDA ESTELAR. Este recorrido exterior, salpicado con una serie de experiencias interactivas de Realidad
        Aumentada, está diseñado para que el visitante explore de manera autónoma el Campus y se prepare para la
        experiencia
        en el DISCOVERY CENTER. Para disfrutar del recorrido completo y no perderse ningún detalle, se recomienda llegar
        al
        punto de inicio de la SENDA ESTELAR al menos 50 minutos antes del horario de reserva para la MIG Station,
        indicado
        en el pase gratuito.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        5. En caso de no completar el recorrido de la SENDA ESTELAR antes de la entrada a la MIG Station, se podrá
        finalizar
        una vez concluida la visita a la parte interior del centro experiencial.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        6. Si el visitante no llega a tiempo para su turno de acceso a la MIG Station, no se garantiza que pueda
        realizar
        la
        visita. En tal caso, deberá realizar una nueva reserva para otra fecha y hora disponible en la web de reservas.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        7. Las reservas se podrán realizar en cualquiera de los horarios disponibles reflejados en el calendario de
        reservas.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        8. La reserva y el acceso son gratuitos.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        9. El usuario podrá realizar una reserva individual o grupal. Se entiende por grupo un máximo de 10 personas.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        10. Se considera visita en grupo aquella conformada por un mínimo de 2 y un máximo de 10 personas.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        11. Para reservas de colectivos tales como colegios, fundaciones, corporativos o cualquier entidad no personal,
        deberán dirigirse a la dirección de correo indicada, especificando sus necesidades. En estos casos, el límite
        será
        de XX? personas, incluido el guía, intérprete, profesor, tutor o responsable que acompañe al grupo.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        12. Las reservas deberán realizarse al menos 24 horas antes de la fecha de visita.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        13. La edad mínima para acceder al DISCOVERY CENTER – MIG Station de manera individual es de 14 años. Los
        menores
        de
        14 años deberán ir acompañados de un adulto.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        14. Los visitantes entre 14 y 18 años que deseen visitar el espacio de forma individual deberán aportar su
        documento
        de identidad (DNI o pasaporte).
      </p>
      <p class="font-Lato-regular text-xs text-white">
        15. Se considera válido cualquier documento que incluya fecha de validez visible o de caducidad, sea nominativo,
        con
        fotografía. En caso de que no incluya fotografía, deberá ir acompañado de DNI o pasaporte. El documento deberá
        ser
        emitido por un órgano competente.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        16. En el interior de la MIG Station podrán existir contenidos destinados a un público específico en términos de
        edad, por lo que aquellos que no cumplan con los criterios de edad establecidos no tendrán acceso a determinadas
        salas. El acceso será regulado por el personal de las instalaciones.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        17. Para realizar la reserva será necesario aportar ciertos datos personales.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        18. La adquisición de un pase individual o grupal da derecho a una única visita a la MIG Station, válida para el
        día
        y la hora indicados.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        19. Cuando se ofrezcan exposiciones complementarias al itinerario habitual, la Dirección General de Madrid In
        Game
        podrá fijar condiciones de acceso diferentes a las generales de la MIG Station.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        20. Acceso para personas con movilidad reducida – DEFINIR.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        21. En el caso de que un usuario individual o grupo no pueda asistir tras haber realizado la reserva, deberá
        cancelarla para permitir que otros interesados puedan reservar en los horarios que previamente habían sido
        bloqueados.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        22. No se admitirán visitas sin reserva previa.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        23. La Dirección General del Campus del Videojuego Madrid In Game, junto con los organizadores de la
        experiencia,
        se
        reservan el derecho de admisión.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        24. La Dirección General del Campus del Videojuego Madrid In Game, junto con los organizadores, se reservan el
        derecho de modificar días y horarios
      </p>
      <p class="font-Lato-regular text-xs text-white">
        25. La reserva carece de valor nominal. Está prohibida su venta o reventa a través de agencias de viajes,
        plataformas de ticketing o cualquier otro canal, y en ningún caso podrá ser canjeada por dinero.
      </p>
      <p class="font-Lato-regular text-xs text-white">
        26. Se informa a todos los visitantes que está expresamente prohibido introducir comida y bebida en el interior
        del espacio MIG Station. Esta medida tiene como objetivo garantizar un ambiente limpio y agradable para los
        visitantes, así como preservar la seguridad y buen funcionamiento del equipamiento que se alberga en su
        interior.
      </p>
    </div>
    <p class="font-Lato-regular text-xs text-white">
      Agradecemos su comprensión y colaboración.
    </p>
  </div>
</div>
