import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function stringToDate(dateString: string): Date | null {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    console.error("Format invalid. Use 'YYYY-MM-DD'.");
    return null;
  }

  const dateObject = new Date(dateString);

  if (isNaN(dateObject.getTime())) {
    console.error('Invalid date.');
    return null;
  }

  return dateObject;
}

export function dateToString(date: Date): string {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date.');
  }

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function dateToNgbDateStruct(date: Date): NgbDateStruct {
  return {
    day: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function formatDateToSpanishString(date: Date): string {
  const daysOfWeek = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];
  const monthsOfYear = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

// Convertir la fecha a la hora española (CET/CEST)
//const options = { timeZone: 'Europe/Madrid', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
const options: Intl.DateTimeFormatOptions = {
  timeZone: 'Europe/Madrid',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  weekday: 'long'
};
const spanishDate = new Date(date.toLocaleString('en-US', options)); // Crear un nuevo objeto de fecha con la hora de Madrid

// Extraer los componentes de la fecha
const utcDayOfWeek = daysOfWeek[spanishDate.getDay()];
const utcDay = spanishDate.getDate();
const utcMonth = monthsOfYear[spanishDate.getMonth()];
const utcYear = spanishDate.getFullYear();
const utcHours = spanishDate.getHours().toString().padStart(2, '0');
const utcMinutes = spanishDate.getMinutes().toString().padStart(2, '0');

  return `${utcDayOfWeek} ${utcDay}, ${utcMonth} ${utcYear} <b>${utcHours}:${utcMinutes} h</b>`;
}
