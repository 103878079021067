import {
  AfterViewInit,
  Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Visit } from 'src/app/shared/models';
import { VisitService } from 'src/app/shared/services';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/shared/services/config.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit, OnChanges {

  @Output() currentSectionChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() currentSection: number = 0
  showLoading = true;
  visits: Visit[] = [];
  visitsToWork: Visit[] = []
  selectedDate: NgbDateStruct | null = null;

  dateSelected: Date = new Date();
  days: string[] = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  months: string[] = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  weekDay: string = this.days[this.dateSelected.getDay()];
  day: string = String(this.dateSelected.getDate()).padStart(2, '0');
  month: string = this.months[this.dateSelected.getMonth()];


  constructor(
    private toastService: ToastrService,
    private visitService: VisitService,
    private configService: ConfigService
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 0) this.init();
  }

  async init(): Promise<void> {
    this.showLoading = true;
    try {
      this.visits = await this.visitService.getTodayVisits();
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) errorMessage = error.error.error;
      else errorMessage = 'No se han podido obtener datos';
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    } finally {
      this.showLoading = false;
    }
  }

  getThresholdDate(): Date {
    const threshold = new Date();
    threshold.setHours(11, 0, 0, 0);
    return threshold;
  }

  datePicked(date: NgbDateStruct) {
    this.dateSelected = new Date(date.year, date.month - 1, date.day);
    this.weekDay = this.days[this.dateSelected.getDay()];
    this.day = String(this.dateSelected.getDate()).padStart(2, '0');
    this.month = this.months[this.dateSelected.getMonth()].padStart(2, '0');
    let body = {
      date: this.dateSelected.getFullYear() + "-" + (this.dateSelected.getMonth() + 1).toString().padStart(2, '0') + "-" + this.dateSelected.getDate().toString().padStart(2, '0'),
    }
    this.configService.getVisitByDay(body).subscribe((data: any) => {
      this.visits = data
      this.visits.forEach((visit: any) => {
        const utcDate = DateTime.fromISO(visit.date, { zone: "UTC", setZone: true });
        const spanishDate = utcDate.setZone("Europe/Madrid");
        visit.date = spanishDate.toFormat("yyyy-MM-dd HH:mm");  // Format as "HH:mm" in Spanish time
      })
      this.visitsToWork = this.visits
    }, (err: any) => { console.log("fallo al pedir dia") })

  }
}
