import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '../models';

@Pipe({
  name: 'booking'
})
export class BookingPipe implements PipeTransform {

  transform(bookings: Booking[], filter: string, filters: string[]): any[] {
    if (!bookings) return [];
    if (!filter && (!filters || filters.length === 0)) return bookings;

    const lowerCaseFilter = filter?.toLocaleLowerCase();

    filters = filters.map((element: string) => {
      if (element == 'Cancelada') return 'cancelled';
      if (element == 'Aceptada') return 'accepted';
      if (element == 'Pase General') return 'particular';
      if (element == 'Pase Especial') return 'collective';
      return element; // Return unchanged elements
    });
    let filtered = bookings.filter(booking => {

      let matchesStatusAndReserveType = true;
      // We iterate over the filters
      for (let i = 0; i < filters.length; i++) {
        // We check if the status or the reserveType is equal to the filter
        if (booking.status != filters[i] && booking.reserveType != filters[i]) {
          // If it is, we return true
          matchesStatusAndReserveType = false;
        }
      }

      if (!matchesStatusAndReserveType) {
        return false;
      }

      // Now we theck if the filter is not empty
      if (lowerCaseFilter === '' || lowerCaseFilter === undefined || lowerCaseFilter === null || lowerCaseFilter.length === 0) {
        // If it is, we return true
        return true;
      }
      return (
        booking.bookingId?.toLowerCase().includes(lowerCaseFilter) ||
        booking.code?.toLowerCase().includes(lowerCaseFilter) ||
        booking.code_visita?.toLowerCase().includes(lowerCaseFilter) ||
        booking.date?.toString().split('T')[0].toLowerCase().includes(lowerCaseFilter) ||
        booking.reserveType?.toLowerCase().includes(lowerCaseFilter) ||
        booking.numVisitor?.toString().toLowerCase().includes(lowerCaseFilter) ||
        booking.userId?.toLowerCase().includes(lowerCaseFilter) ||
        booking.status?.toLowerCase().includes(lowerCaseFilter)
      );
    });
    return filtered;
  }


}
