<h1 class="p_5 pb-0">\\RESERVAS</h1>
<div class="table px-5 m_tables">
  <div class="top-elements d-flex flex-row-reverse gap-4">
    <button class="filter btn button-border-left font-Lato-regular text-xxs pe-3" (click)="openPopup()">
      <img src="assets/hamburger-icon.svg" alt="closeButton"> Filtros
    </button>
    <!-- Pop-up -->
    <div class="popup" *ngIf="isPopupVisible" (click)="onBackdropClick($event)">
      <div class="popup-content">
        <span class="close" (click)="closePopup()">&times;</span>
        <p class="p-filtrado">FILTRADO</p>
        <div class="popup-content-button">
          <button class="popup-button" (click)="removeFilter('Pase Especial'); addFilter('Pase General')">Pase
            General</button>
          <button class="popup-button" (click)="removeFilter('Pase General');  addFilter('Pase Especial')">Pase
            Especial</button>
          <!-- <button class="popup-button" (click)="addFilter('Interna')">Interna</button> -->
          <button class="popup-button" (click)="removeFilter('Cancelada'); addFilter('Aceptada')">Aceptada</button>
          <button class="popup-button" (click)="removeFilter('Aceptada'); addFilter('Cancelada')">Cancelada</button>
        </div>
        <app-calendar *ngIf="currentSection == 1" [extraPadding]="true" [showFooter]="false" [bgColor]="'#000'"
          [isBorderless]="true" [showDay]="true" [paddingl]="0" [paddingr]="0" [outsideDays]="'collapsed'"
          (emitDayBookings)="getSelectedDate($event)"></app-calendar>
      </div>
    </div>
    <input class=".search-bar" type="text" name="search" id="search" placeholder="Búsqueda..."
      [(ngModel)]="searchFilter">
    <!-- Filtros -->
    <div class="filters" *ngFor="let filtro of this.filters">
      {{ filtro }}
      <img src="assets/images/btn_close.svg" alt="closeButon" (click)="removeFilter(filtro)">
    </div>
  </div>
  <div class="table-content mt-4">
    <div class="d-flex table_header">
      <div class="header col col-1">
        ID Visita
        <img (click)="toggleSortVisitIds()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        ID Reserva
        <img (click)="toggleSortBookingIds()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        Día
        <img (click)="toggleSortDates()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        Hora
        <img (click)="toggleSortTimes()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-2">
        Tipo de pase
        <img (click)="toggleSortPass()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        Personas
        <img (click)="toggleSortBookings()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-2">
        Usuario
        <img (click)="toggleSortUsers()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        Estado
        <img (click)="toggleSortStatus()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-1">
        Acciones
        <img src="assets/filter-icon.svg" alt="filter">
      </div>
    </div>
    <div class="table_body">
      <div *ngFor="let booking of sortedBookings | booking: searchFilter : filters"
        class="d-flex align-items-center table_row">
        <span class="col col-1 me-auto text-center">{{ booking.code_visita }}</span>
        <span class="col col-1 me-auto text-center">{{ booking.code }}</span>
        <span class="col col-1 me-auto text-center">{{ booking.date | date: 'yyyy-MM-dd'
          }}</span>
        <span class="col col-1 me-auto text-center">{{ booking.date | date: 'HH:mm' }}h </span>
        <span class="col col-2 me-auto text-center">Pase {{ booking.reserveType == 'collective' ? 'Especial' : 'General'
          }}</span>
        <span class="col col-1 me-auto text-center">{{ booking.numVisitor }}</span>
        <span class="col col-2 me-auto text-center">{{ booking.userId }}</span>
        <span class="col col-1 me-auto text-center" [ngClass]="booking.status">{{ booking?.status.toLocaleUpperCase()
          }}</span>
        <div class="d-flex icon_container col col-1 me-auto text-center">
          <img src="assets/delete-icon.svg" alt="delete" (click)="deleteBooking(booking.id, booking.code)">
        </div>
      </div>
    </div>
  </div>
