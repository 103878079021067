export class Log {
    private id: number;
    private actionDo: string;
    private actionType: string;
    private moment: string;
    private resultDo: string;
    private targetDo: string;
    private userDo: string

    constructor(
        id: number,
        actionDo: string,
        actionType: string,
        moment: string,
        resultDo: string,
        targetDo: string,
        userDo: string
    ) {
        this.id = id;
        this.actionDo = actionDo;
        this.actionType = actionType
        this.moment = moment;
        this.resultDo = resultDo;
        this.targetDo = targetDo;
        this.userDo = userDo;
    }

    // Getters
    public getId(): number {
        return this.id;
    }

    public getActionDo(): string {
        switch (this.actionDo) {
            case "delete":
                return "borrar"
            case "create":
                return "crear"
            case "edit":
                return "actualizar"
            case "update":
                return "actualizar"
            default:
                return this.actionDo
        }
    }

    public getActionType(): string {
        return this.actionType;
    }
    public getActionDoHumanReadable(): string {
        switch (this.actionDo) {
            case 'delete':
                return "eliminado"
            case 'create':
                return "creado"
            case 'update':
            case 'edit':
                return "actualizado"
            default:
                return this.actionDo
        }
    }
    public getResultHumanReadable(): string {
        switch (this.resultDo) {
            case 'success':
                return " exitosamente."
            default:
                return " con errores."
        }
    }

    public getMoment(): string {
        return this.moment;
    }

    public getResultDo(): string {
        return this.resultDo;
    }

    public getTargetDo(): string {
        return this.targetDo;
    }

    public getUserDo(): string {
        return this.userDo;
    }

    // Setters
    public setId(id: number): void {
        this.id = id;
    }

    public setActionDo(actionDo: string): void {
        this.actionDo = actionDo;
    }

    public setActionType(actionType: string): void {
        this.actionType = actionType;
    }

    public setMoment(moment: string): void {
        this.moment = moment;
    }

    public setResultDo(resultDo: string): void {
        this.resultDo = resultDo;
    }

    public setTargetDo(targetDo: string): void {
        this.targetDo = targetDo;
    }

    public setUserDo(userDo: string): void {
        this.userDo = userDo;
    }

}

