export class Template {
  private id: number;
  private templateName: string;
  private templateStatus: string;
  private morningType: string;
  private morningStartHour: Date;
  private morningEndTime: Date;
  private morningVisitTime: number;
  private morningMaxPeople: number;
  private morningMaxGroupPeople: number;
  private afternoonType: string;
  private afternoonStartHour: Date;
  private afternoonEndTime: Date;
  private afternoonVisitTime: number;
  private afternoonMaxPeople: number;
  private afternoonMaxGroupPeople: number;
  private templateColour: string;
  private nota: string;

  constructor(
    id: number,
    templateName: string,
    templateStatus: string,
    morningType: string,
    morningStartHour: Date,
    morningEndTime: Date,
    morningVisitTime: number,
    morningMaxPeople: number,
    morningMaxGroupPeople: number,
    afternoonType: string,
    afternoonStartHour: Date,
    afternoonEndTime: Date,
    afternoonVisitTime: number,
    afternoonMaxPeople: number,
    afternoonMaxGroupPeople: number,
    templateColour: string,
    nota: string
  ){
    this.id = id;
    this.templateName = templateName;
    this.templateStatus = templateStatus;
    this.morningType = morningType;
    this.morningStartHour = morningStartHour;
    this.morningEndTime = morningEndTime;
    this.morningVisitTime = morningVisitTime;
    this.morningMaxPeople = morningMaxPeople
    this.morningMaxGroupPeople = morningMaxGroupPeople;
    this.afternoonType = afternoonType;
    this.afternoonStartHour = afternoonStartHour;
    this.afternoonEndTime = afternoonEndTime;
    this.afternoonVisitTime = afternoonVisitTime;
    this.afternoonMaxPeople = afternoonMaxPeople;
    this.afternoonMaxGroupPeople = afternoonMaxGroupPeople;
    this.templateColour = templateColour;
    this.nota = nota;
  }

  // Getters
  public getId(): number {
    return this.id;
  }

  public getTemplateName(): string {
    return this.templateName;
  }

  public getTemplateStatus(): string {
    return this.templateStatus;
  }

  public getMorningType(): string {
    return this.morningType;
  }

  public getMorningStartHour(): Date {
    return this.morningStartHour;
  }

  public getMorningEndTime(): Date {
    return this.morningEndTime;
  }

  public getMorningVisitTime(): number {
    return this.morningVisitTime;
  }

  public getMorningMaxPeople(): number {
    return this.morningMaxPeople;
  }

  public getMorningMaxGroupPeople(): number {
    return this.morningMaxGroupPeople;
  }

  public getAfternoonType(): string {
    return this.afternoonType;
  }

  public getAfternoonStartHour(): Date {
    return this.afternoonStartHour;
  }

  public getAfternoonEndTime(): Date {
    return this.afternoonEndTime;

  }

  public getAfternoonVisitTime(): number {
    return this.afternoonVisitTime;
  }

  public getAfternoonMaxPeople(): number {
    return this.afternoonMaxPeople;
  }

  public getAfternoonMaxGroupPeople(): number {
    return this.afternoonMaxGroupPeople;
  }

  public getTemplateColour(): string {
    return this.templateColour;
  }

  public getNota(): string {
    return this.nota;
  }

  // Setters
  public setId(id: number): void {
    this.id = id;
  }

  public setTemplateName(templateName: string): void {
    this.templateName = templateName;
  }

  public setTemplateStatus(templateStatus: string): void {
    this.templateStatus = templateStatus;
  }

  public setMorningType(morningType: string): void {
    this.morningType = morningType;
  }

  public setMorningStartHour(morningStartHour: Date): void {
    this.morningStartHour = morningStartHour;
  }

  public setMorningEndTime(morningEndTime: Date): void {
    this.morningEndTime = morningEndTime;
  }

  public setMorningVisitTime(morningVisitTime: number): void {
    this.morningVisitTime = morningVisitTime;
  }

  public setMorningMaxPeople(morningMaxPeople: number): void {
    this.morningMaxPeople = morningMaxPeople;
  }

  public setMorningMaxGroupPeople(morningMaxGroupPeople: number): void {
    this.morningMaxGroupPeople = morningMaxGroupPeople;
  }

  public setAfternoonType(afternoonType: string): void {
    this.afternoonType = afternoonType;
  }

  public setAfternoonStartHour(afternoonStartHour: Date): void {
    this.afternoonStartHour = afternoonStartHour;
  }

  public setAfternoonEndTime(afternoonEndTime: Date): void {
    this.afternoonEndTime = afternoonEndTime;
  }

  public setAfternoonVisitTime(afternoonVisitTime: number): void {
    this.afternoonVisitTime = afternoonVisitTime;
  }

  public setAfternoonMaxPeople(afternoonMaxPeople: number): void {
    this.afternoonMaxPeople = afternoonMaxPeople;
  }

  public setAfternoonMaxGroupPeople(afternoonMaxGroupPeople: number): void {
    this.afternoonMaxGroupPeople = afternoonMaxGroupPeople;
  }

  public setTemplateColour(templateColour: string): void {
    this.templateColour = templateColour;
  }

  public setNota(nota: string): void {
    this.nota = nota;
  }
}

