<h1 class="p-3 p-xxl-5">\\CONFIGURACIÓN MIG STATION</h1>
<div class="d-flex flex-wrap align-items-start px-5 pb-5 w-100 overflow-hidden h-100">
  <div class="d-flex gap-5 w-100 overflow-hidden h-100">
    <div class="d-flex flex-column w-50 animated overflow-auto h-100">
      <app-calendar *ngIf="currentSection == 2 && seeCalendar" [extraPadding]="true" [seeColors]="true"
        [outsideDays]="'collapsed'" [showLegend]="true" [showFooter]="true" [showDay]="true"
        (datePicked)="datePicked($event)" (buttonClicked)="changeDivSelector($event)" [divSelector]="divSelector"
        [ngStyle]="{'display': currentSection == 2 ? 'block':'none'}" class="h-100"></app-calendar>
    </div>
    <div class="d-flex flex-column w-50">
      <!-- Selector dia -->
      <div class="side-day div-container overflow-auto" [ngStyle]="{
        'opacity': divSelector == 0 ? '1' : '0',
        'display': divSelector == 0 ? 'block': 'none',
        'visibility': divSelector == 0 ? 'visible': 'hidden',
      }">
        <div class="card-content" [ngStyle]="{
          'filter': sideDayPopup ? 'blur(3px)' : 'none',
        }">
          <div class="header pt-4 pb-3 h-100">
            <div class="day-selector"><span [ngClass]="{ 'selected': morningSelected }"
                (click)="changeSplit(true)">Mañana</span><span [ngClass]="{ 'selected': !morningSelected }"
                (click)="changeSplit(false)">Tarde</span></div>
            <h3>{{ weekDay }} {{ day }} {{ month }}</h3>
          </div>
          <div class="wrapper overflow-hidden h-75 ">
            <div class="table-body overflow-auto">
              <div *ngIf="dailyVisits <= 0" class="no-content">No existen visitas para el dia seleccionado</div>

              <div class="row-table" *ngFor="let dailyVisit of dailyVisits">
                <div class="column-table col col-2">{{ dailyVisit.date | date: 'H:mm' }}</div>
                <div class="column-table col col-10">
                  <div *ngIf="dailyVisit.num_users < dailyVisit.visite_size">
                    <div class="col col-3 code">{{ dailyVisit.code }}</div>
                    <div class="col col-5">Pase <span *ngIf="dailyVisit.reserve_type == 'particular'">
                        General</span><span *ngIf="dailyVisit.reserve_type == 'collective'"> Especial</span></div>
                    <div class="col col-3">{{ dailyVisit.num_users }}/{{ dailyVisit.visite_size }} Personas</div>
                    <div class="col col-1"><img (click)="editVisit(dailyVisit)" src="assets/edit.svg" alt="edit"></div>
                  </div>
                  <div *ngIf="dailyVisit.num_users >= dailyVisit.visite_size" class="container-full">
                    <div class="col col-3 code">{{ dailyVisit.code }}</div>
                    <div class="col col-5">Pase <span *ngIf="dailyVisit.reserve_type == 'particular'">
                        General</span><span *ngIf="dailyVisit.reserve_type == 'collective'"> Especial</span></div>
                    <div class="col col-3">{{ dailyVisit.num_users }}/{{ dailyVisit.visite_size }} Personas</div>
                    <div class="col col-1"><img (click)="editVisit(dailyVisit.id)" src="assets/edit.svg" alt="edit">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom-add-visit d-flex justify-content-end py-3 pe-4 align-items-center h-100">
            <div>Añadir Visita<img (click)="addVisit('10:30')" src="assets/images/btn_mas.svg" alt="edit"></div>
          </div>
        </div>
        <!-- Popup crear nueva visita -->
        <div class="message-popup" [ngStyle]="{
          'opacity': sideDayPopup ? '1' : '0',
          'pointer-events' : sideDayPopup ? 'all' : 'none'
        }">
          <div class="header-popup">
            <div (click)="closeVisit()" class="image"><img src="assets/images/btn_close.svg" alt="close"></div>
            <h4 *ngIf="isAddVisit">Añadir visita</h4>
            <h4 *ngIf="!isAddVisit">Editar visita</h4>
            <p *ngIf="isAddVisit">{{ newVisitDate.getDate() }}/{{ newVisitDate.getMonth() }}/{{
              newVisitDate.getFullYear() }}</p>
            <p *ngIf="!isAddVisit">ID {{ this.visitEditSelected.code }}</p>
          </div>
          <div class="content-popup">
            <form [formGroup]="popupForm" (ngSubmit)="popupFormSubmit()" autocomplete="off">
              <div class="container-popup">
                <div class="input">
                  <p>Hora visita</p>
                  <input type="time" formControlName="timeVisit">
                </div>
                <div class="input">
                  <p>Franja Horaria</p>
                  <select formControlName="franjaHoraria">
                    <option value="morning">Mañana</option>
                    <option value="afternoon">Tarde</option>
                  </select>
                </div>
              </div>
              <div class="container-popup">
                <div class="input">
                  <p>Tipo de visita</p>
                  <select formControlName="visitType">
                    <option value="particular">Pase General</option>
                    <option value="collective">Pase Especial</option>
                  </select>
                </div>
                <div class="input">
                  <p>Tiempo visita</p>
                  <input type="number" formControlName="visitTime">
                </div>
              </div>
              <div class="container-popup">
                <div class="input">
                  <p>Personas por grupo</p>
                  <input type="number" formControlName="usersPerGroup">
                </div>
                <div class="input">
                  <p>Max Personas</p>
                  <input type="number" formControlName="maxUsers">
                </div>
              </div>
              <div class="mt-4 w-auto d-flex flex-row gap-3">
                <div *ngIf="!isAddVisit" class="button-triangle-wrapper m-auto">
                  <button (click)="deleteVisit()" type="button" class="button">Eliminar</button>
                </div>
                <div class="button-triangle-wrapper m-auto">
                  <button class="button" type="submit">
                    <span *ngIf="isAddVisit">Añadir</span>
                    <span *ngIf="!isAddVisit">Modificar</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Editar Calendario -->
      <div class="div-container edit-calendar" [ngStyle]="{
        'opacity': this.divSelector == 1 ? '1' : '0',
        'display': divSelector == 1 ? 'block': 'none',
        'visibility': divSelector == 1 ? 'visible': 'hidden',
      }">
        <app-calendar *ngIf="currentSection == 2 && seeCalendar" [isConfiguration]="true" [extraPadding]="true"
          [showLegend]="false" [outsideDays]="'collapsed'" [showFooter]="false" [showTemplate]="true" [showDay2]="true"
          [showClose]="true" (resetConfig)="resetConfig()" [ngStyle]="{'display': currentSection == 2 ? 'block':'none'}"
          (closeEvent)="closeEditCalendar()" (buttonClicked)="changeDivSelector($event)"
          class="overflow-hidden h-100"></app-calendar>
      </div>

      <!-- Editar Día -->
      <div class="edit-day div-container px-5 py-2" [ngStyle]="{
        'opacity': this.divSelector == 2 ? '1' : '0',
        'display': divSelector == 2 ? 'block': 'none',
        'visibility': divSelector == 2 ? 'visible': 'hidden',
      }">
        <div class="header pt-1 p-xxl-4">
          <h4>EDITAR DÍA</h4>
          <p>{{ dateSelected.getDate() }}/{{ dateSelected.getMonth() + 1}}/{{ dateSelected.getFullYear() }}</p>
        </div>
        <div class="edit-body">
          <form [formGroup]="editDayForm" (ngSubmit)="editDayFormSubmit()" autocomplete="off">
            <!-- Horario Mañana -->
            <div class="subtitle">
              <h5>DÍA</h5>
              <p>(hasta las 14h)</p>
            </div>
            <div class="container">
              <div class="input">
                <p>Tipo de visita</p>
                <select formControlName="tipoVisitaMañana" id="tipoVisitaMañana" name="tipoVisitaMañana">
                  <option value="particular">Pase General</option>
                  <option value="collective">Pase Especial</option>
                </select>
              </div>
              <div class="input">
                <p>Horario de mañana</p>
                <div>
                  <input type="time" formControlName="horarioMañanaInicio" id="horarioMañanaInicio"
                    name="horarioMañanaInicio">
                  <span>-</span>
                  <input type="time" formControlName="horarioMañanaFinal" id="horarioMañanaFinal"
                    name="horarioMañanaFinal">
                </div>
              </div>
            </div>
            <div class="container">
              <div class="input _45">
                <p>Tiempo entre visitas (minutos)</p>
                <input type="number" formControlName="tiempoVisitasMañana" id="tiempoVisitasMañana"
                  name="tiempoVisitasMañana" placeholder="En minutos">
              </div>
              <div class="input">
                <div class="divided-info">
                  <p>Personas visita</p>
                  <p>Personas por grupo</p>
                </div>
                <div>
                  <input type="number" formControlName="personasVisitaMañanaInicio" id="personasVisitaMañanaInicio"
                    name="personasVisitaMañanaInicio">
                  <span class="no-color">-</span>
                  <input type="number" formControlName="personasVisitaMañanaFinal" id="personasVisitaMañanaFinal"
                    name="personasVisitaMañanaFinal">
                </div>
              </div>
            </div>
            <!-- Horario tarde -->
            <div class="subtitle">
              <h5>TARDE</h5>
              <p>(De 14h a cierre)</p>
            </div>
            <div class="container">
              <div class="input">
                <p>Tipo de visita</p>
                <select formControlName="tipoVisitaTarde" id="tipoVisitaTarde" name="tipoVisitaTarde">
                  <option value="particular">Pase General</option>
                  <option value="collective">Pase Especial</option>
                </select>
              </div>
              <div class="input">
                <p>Horario de tarde</p>
                <div>
                  <input type="time" formControlName="horarioTardeInicio" id="horarioTardeInicio"
                    name="horarioTardeInicio">
                  <span>-</span>
                  <input type="time" formControlName="horarioTardeFinal" id="horarioTardeFinal"
                    name="horarioTardeFinal">
                </div>
              </div>
            </div>
            <div class="container">
              <div class="input _45">
                <p>Tiempo entre visitas (minutos)</p>
                <input type="number" formControlName="tiempoVisitasTarde" id="tiempoVisitasTarde"
                  name="tiempoVisitasTarde" placeholder="En minutos">
              </div>
              <div class="input">
                <div class="divided-info">
                  <p>Personas visita</p>
                  <p>Personas por grupo</p>
                </div>

                <div>
                  <input type="number" formControlName="personasVisitaTardeInicio" id="personasVisitaTardeInicio"
                    name="personasVisitaTardeInicio">
                  <span class="no-color">-</span>
                  <input type="number" formControlName="personasVisitaTardeFinal" id="personasVisitaTardeFinal"
                    name="personasVisitaTardeFinal">
                </div>
              </div>
            </div>
            <!-- Boton -->
            <div class="container pt-2 p-xxl-5 d-flex flex-column align-items-center">
              <div class="button-triangle-wrapper w-auto">
                <button class="button" id="confirmarED" [disabled]="!editDayForm.valid">Confirmar</button>
              </div>
              <div class="text-center">Por favor, rellene todos los campos</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Popup confirmacion -->
<div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
  'opacity': confirmationPopup ? '1': '0',
  'pointer-events': confirmationPopup ? 'all': 'none'
}">
  <div class="popup-content">
    <p class="py-2">¿Seguro que quiere borrar la visita?</p>
    <div class="py-2">
      <div class="button-triangle-wrapper w-auto"><button (click)="this.confirmationPopup = false"
          class="button">Cancelar</button></div>
      <div class="button-triangle-wrapper w-auto"><button (click)="confirmDeleteVisit()"
          class="button">Confirmar</button></div>
    </div>
  </div>
</div>
