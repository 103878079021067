import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-aside',
  /* standalone: true, */
  /*  imports: [CommonModule], */
  templateUrl: './admin-aside.component.html',
  styleUrls: ['./admin-aside.component.scss']
})
export class AdminAsideComponent implements OnInit {
  @Input() currentSection: number = 0;
  @Output() currentSectionChange: EventEmitter<number> = new EventEmitter<number>();
  userId: string = ""
  userRole: string = "";

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.user$.subscribe((user: User | null) => {
      if (user) {
        this.userId = user.id
        this.userRole = user.role
      }
    });
  }

  setCurrentSection(section: number) {
    if (section == this.currentSection) return;
    this.currentSection = section;
    this.currentSectionChange.emit(this.currentSection);
  }

  logout = () => this.authService.logout();

}
