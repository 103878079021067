import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User, UserTypeEnum } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'app-landing-layout',
  templateUrl: './landing-layout.component.html',
  styleUrls: ['./landing-layout.component.scss'],
})
export class LandingLayoutComponent {
  private subscriptions: Subscription;

  constructor(private authService: AuthService, private router: Router) {
    this.subscriptions = this.authService.user$.subscribe(
      (userLogged: User | null) => {
        if (userLogged?.role === UserTypeEnum.Client) {
          this.router.navigate(['/reservas']);
        }
        if (userLogged?.role === UserTypeEnum.Control) {
          this.router.navigate(['/visitas']);
        }

        if (userLogged?.role === UserTypeEnum.Admin || userLogged?.role === UserTypeEnum.SuperAdmin) {
          this.router.navigate(['/admin']);
        }
      },
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
