import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import {
  QRCodeCheckAnswer,
  User,
  Visit,
  VisitCategoryEnum,
} from 'src/app/shared/models';
import { VisitService } from 'src/app/shared/services';
import { BarcodeFormat } from '@zxing/library';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DisplayFieldCSSI } from 'src/app/shared/interfaces/css.interfaces';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.scss'],
})
export class VisitComponent implements OnInit {
  showLoading = true;
  showLoadingStartExperience = false;
  showLoadingAddBooking = false;

  showManualBooking = false;

  showQRScanner = false;
  isQRNotValid = false;
  isCheckingQRCode = false;

  BarcodeFormat = BarcodeFormat;
  availableDevices: MediaDeviceInfo[] = [];
  currentDevice?: MediaDeviceInfo;

  visit?: Visit;
  users: User[] = [];

  userId!: string;

  manualBookingForm!: FormGroup;
  bookingId!: FormControl;
  email!: FormControl;

  VisitCategoryEnum = VisitCategoryEnum;

  qrAnswer!: QRCodeCheckAnswer;

  @ViewChild('validateBookingModal', { static: true })
  validateBookingModal!: TemplateRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private toastService: ToastrService,
    private visitService: VisitService,
    private router: Router,
    private modalService: NgbModal,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.createManualBookingForm();
    const { id: visitId } = this.route.snapshot.params;
    this.init(visitId);
  }

  createManualBookingForm(): void {
    this.bookingId = new FormControl('', [Validators.required.bind(this)]);
    this.email = new FormControl('', [
      Validators.required.bind(this),
      Validators.email.bind(this),
    ]);

    this.manualBookingForm = new FormGroup({
      bookingId: this.bookingId,
      email: this.email,
    });
  }

  async init(visitId: string): Promise<void> {
    this.showLoading = true;
    try {
      const response = await this.visitService.getVisitInfo({
        visitId: +visitId,
      });
      this.visit = response.visit;
      this.users = response.users;
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido obtener datos';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    } finally {
      this.showLoading = false;
    }
  }

  validateBooking(): void {
    this.getAvailableMediaDevices();
    this.showQRScanner = true;
  }

  async startExperience(visitId: number): Promise<void> {
    this.showLoadingStartExperience = true;
    if (!this.visit) return;

    try {
      await this.visitService.startExperience({ visitId });
      this.toastService.success('Experiencia iniciada correctamente!!!');
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido iniciar la experiencia';
      }
      this.toastService.error(errorMessage, 'Esta visita ya ha sido iniciada.');
    } finally {
      this.showLoadingStartExperience = false;
      setTimeout(() => {
        this.router.navigate(['/visitas']);
      }, 3000);
    }
  }

  handleQrCodeResult(resultString: string): void {
    this.isQRNotValid = false;

    if (this.isURL(resultString)) {
      this.isQRNotValid = true;
      this.isCheckingQRCode = false;
      return;
    }

    const [bookingId, userId] = resultString.split(':', 2);

    if (
      !bookingId ||
      bookingId.length === 0 ||
      !userId ||
      userId.length === 0
    ) {
      this.isQRNotValid = true;
      this.isCheckingQRCode = false;
      return;
    }

    if (!this.visit?.id) return;

    this.checkQRCode({ bookingId, visitId: this.visit.id });
    this.userId = userId;
    this.isCheckingQRCode = true;
  }
  // handleQrFailureCodeResult() {
  //   console.log('QR Code Failure Result:');
  //   // Maneja el resultado del escaneo
  // }

  getAvailableMediaDevices() {
    // Get all available devices (cameras).
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: MediaDeviceInfo[]) => {
        this.availableDevices = devices.filter(
          (device) => device.kind === 'videoinput',
        );

        if (this.availableDevices.length > 0) {
          const backCamera = this.availableDevices.find((device) =>
            device.label.toLowerCase().includes('back'),
          );
          if (backCamera) {
            this.currentDevice = backCamera;
          } else {
            this.currentDevice = this.availableDevices[0];
          }
        }
      });
  }

  async checkQRCode({
    bookingId,
    visitId,
  }: {
    bookingId: string;
    visitId: number;
  }): Promise<void> {
    try {
      if (!this.isQRNotValid && this.isCheckingQRCode) return;

      const response = await this.visitService.checkQRBookingWithVisit({
        bookingId,
        visitId,
      });

      this.qrAnswer = response;

      this.handleBack();
      this.isCheckingQRCode = false;

      await this.openModal(this.validateBookingModal, 'sm');
    } catch (error) {}
  }

  manualBooking() {
    this.showManualBooking = true;
  }

  showQRReader() {
    this.showManualBooking = false;
    this.showQRScanner = true;
  }

  async checkManualBooking(): Promise<void> {
    if (!this.manualBookingForm.valid) {
      this.validateAllFormFields(this.manualBookingForm);
      return;
    }

    if (!this.visit?.id) return;

    try {
      const response = await this.visitService.checkQRBookingWithVisit({
        visitId: this.visit?.id,
        bookingId: this.bookingId.value,
      });

      this.qrAnswer = response;

      this.userId = this.email.value;

      this.handleBack();
      this.isCheckingQRCode = false;
      this.manualBookingForm.reset();

      await this.openModal(this.validateBookingModal, 'sm');
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Reserva no validada!!';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    }
  }

  async joinParty(): Promise<void> {
    if (!this.visit || !this.qrAnswer.bookingCode) return;
    try {
      await this.visitService.joinParty({
        bookingId: this.qrAnswer.bookingCode,
        visitId: this.visit.id,
        userId: this.userId,
      });
      this.toastService.success('Se ha unido a la visita!!');
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'Error al validar!!';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    } finally {
      this.modalService.dismissAll();
      const { id: visitId } = this.route.snapshot.params;
      this.init(visitId);
    }
  }

  async openModal(
    content: TemplateRef<HTMLElement>,
    size: 'sm' | 'lg' | 'xl' | string = 'lg',
  ): Promise<void> {
    const modalRef: NgbModalRef = this.modalService.open(content, {
      centered: true,
      backdrop: 'static',
      size,
    });
    try {
      await modalRef.result;
    } catch (error) {
      // TODO: handle error
    } finally {
      return;
    }
  }

  handleBack(): void {
    if (this.showQRScanner || this.showManualBooking) {
      this.showQRScanner = false;
      this.showManualBooking = false;
      return;
    }
    this.location.back();
  }

  isURL(input: string): boolean {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocolo opcional (http o https)
        '((([a-zA-Z\\d]([a-zA-Z\\d-]*[a-zA-Z\\d])*)\\.)+[a-zA-Z]{2,}|' + // Dominio
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // O IP (v4)
        '(\\:\\d+)?(\\/[-a-zA-Z\\d%_.~+]*)*' + // Puerto y path
        '(\\?[;&a-zA-Z\\d%_.~+=-]*)?' + // Query string
        '(\\#[-a-zA-Z\\d_]*)?$',
      'i', // Fragmento
    );
    return !!urlPattern.test(input);
  }

  async changeCategory(newCategory: VisitCategoryEnum): Promise<void> {
    if (!this.visit) return;

    if (newCategory === this.visit.category) return;

    try {
      await this.visitService.changeVisitCategory({
        visitId: this.visit?.id,
        category: newCategory,
      });
      this.toastService.success('Categoría actualizada con éxito!!');
      const { id: visitId } = this.route.snapshot.params;
      this.init(visitId);
    } catch (error) {
      let errorMessage = '';
      if (error instanceof HttpErrorResponse) {
        errorMessage = error.error.error;
      } else {
        errorMessage = 'No se han podido añadir la reserva';
      }
      this.toastService.error(errorMessage, 'Oops! Algo ha ido mal!!');
    }
  }

  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof FormControl) {
        control.markAsDirty({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  displayFieldCss(field: FormControl): DisplayFieldCSSI {
    return {
      'is-invalid': this.isFieldInvalid(field),
      'is-valid': this.isFieldValid(field),
    };
  }

  isFieldValid(field: FormControl): boolean {
    return field.valid && field.dirty;
  }

  isFieldInvalid(field: FormControl): boolean {
    return field.invalid && field.dirty;
  }
}
