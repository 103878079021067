import {
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Provider,
} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  FooterComponent,
  HeaderComponent,
  HeaderControlComponent,
  LandingHeaderComponent,
  LeftMenuComponent,
  TitleComponent,
  ValidateBookingModalComponent,
} from 'src/app/shared/components';
import {
  ControlLayoutComponent,
  DashboardLayoutComponent,
  LandingLayoutComponent,
} from 'src/app/shared/layouts';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {
  AuthService,
  BookingService,
  VisitService,
} from 'src/app/shared/services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import {
  IsAdminLoggedGuard,
  IsClientLoggedGuard,
  IsControlLoggedGuard,
} from 'src/app/shared/guards';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

const PAGES: any[] = [];
const COMPONENTS: any[] = [
  FooterComponent,
  HeaderComponent,
  HeaderControlComponent,
  LandingHeaderComponent,
  LeftMenuComponent,
  TitleComponent,
  ValidateBookingModalComponent,
];
const PROVIDERS: Provider[] = [
  AuthService,
  BookingService,
  IsClientLoggedGuard,
  IsControlLoggedGuard,
  IsAdminLoggedGuard,
  VisitService,
  // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
  // { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
];
const MODULES: any[] = [
  RouterModule,
  CommonModule,
  NgbModule,
  ZXingScannerModule,
];
const DIRECTIVES: Provider[] = [];
const PIPES: any[] = [];
const LAYOUTS: any[] = [
  ControlLayoutComponent,
  DashboardLayoutComponent,
  LandingLayoutComponent,
];
@NgModule({
  imports: [
    ...(MODULES as []),
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
      enableHtml: true,
      iconClasses: {
        info: '',
        success: '',
        warning: '',
      },
    }),
  ],
  declarations: [
    ...(LAYOUTS as []),
    ...(PAGES as []),
    ...(COMPONENTS as []),
    ...(DIRECTIVES as []),
    ...(PIPES as []),
  ],
  exports: [
    ...(PAGES as []),
    ...(COMPONENTS as []),
    ...(DIRECTIVES as []),
    ...(MODULES as []),
    ...(PIPES as []),
    ToastrModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...PROVIDERS,
        ...DIRECTIVES,
        { provide: LOCALE_ID, useValue: 'es-ES' },
      ],
    } as ModuleWithProviders<SharedModule>;
  }
}
