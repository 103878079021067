import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Log } from 'src/app/shared/models/Log';
import { LogsService } from 'src/app/shared/services/logs.service';

@Component({
  selector: 'app-admin-logs',
  templateUrl: './admin-logs.component.html',
  styleUrls: ['./admin-logs.component.scss']
})
export class AdminLogsComponent implements OnInit, OnInit {

  @Input() currentSection: number = 0
  isPopupVisible = false;
  model: NgbDateStruct | undefined;
  logs: Log[] = [];
  sortedLogs: Log[] = [];
  searchFilter: string = "";
  filters: string[] = [];
  sortOrder: 'asc' | 'desc' = 'asc';

  constructor(
    private logsService: LogsService,
  ) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 5) this.getLogs();
  }

  getLogs(): void {
    this.logsService.getLogs().subscribe((logs: any) => {
      this.logs = logs.map((log: any) => {
        return new Log(
          log.id,
          log.action_do,
          log.action_type,
          log.moment,
          log.result_do,
          log.target_do,
          log.user_do,
        );
      })
      this.sortedLogs = [...this.logs];
    })
  }

  addFilter(filter: string) {
    if (this.filters.indexOf(filter) === -1) this.filters = [...this.filters, filter];
  }

  removeFilter(filter: string) {
    this.filters = this.filters.filter(item => item !== filter);
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  onBackdropClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popupContent = document.querySelector('.popup-content');

    if (popupContent && !popupContent.contains(target)) {
      this.closePopup();
    }
  }

  toggleSortDate(): void {
    if (this.sortOrder === 'asc') {
      this.sortedLogs = [...this.logs].sort((a, b) => a.getMoment().localeCompare(b.getMoment()));
      this.sortOrder = 'desc';
    } else {
      this.sortedLogs = [...this.logs].sort((a, b) => b.getMoment().localeCompare(a.getMoment()));
      this.sortOrder = 'asc';
    }
  }

  toggleSortUsers(): void {
    if (this.sortOrder === 'asc') {
      this.sortedLogs = [...this.logs].sort((a, b) => a.getUserDo().localeCompare(b.getUserDo()));
      this.sortOrder = 'desc';
    } else {
      this.sortedLogs = [...this.logs].sort((a, b) => b.getUserDo().localeCompare(a.getUserDo()));
      this.sortOrder = 'asc';
    }
  }

  toggleSortChanges(): void {
    if (this.sortOrder === 'asc') {
      this.sortedLogs = [...this.logs].sort((a, b) => a.getActionDo().localeCompare(b.getActionDo()));
      this.sortOrder = 'desc';
    } else {
      this.sortedLogs = [...this.logs].sort((a, b) => b.getActionDo().localeCompare(a.getActionDo()));
      this.sortOrder = 'asc';
    }
  }

}
