import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { UserTypeEnum } from 'src/app/shared/models';
import { AuthService } from 'src/app/shared/services';
@Injectable()
export class IsClientLoggedGuard {
  constructor(private router: Router, private authService: AuthService) { }
  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      filter((user) => user !== null),
      map((user) => {
        if (user?.role === UserTypeEnum.Client) {
          return true;
        }

        if (user?.role === UserTypeEnum.Control) {
          void this.router.navigate(['/visitas']);
          return false;
        }

        if (user?.role === UserTypeEnum.Admin || user?.role === UserTypeEnum.SuperAdmin) {
          void this.router.navigate(['/admin']);
          return true;
        }

        void this.router.navigate(['/']);
        return false;
      }),
    );
  }
}
