import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Booking } from 'src/app/shared/models';
import { BookingService } from 'src/app/shared/services';

@Component({
  selector: 'app-admin-booking',
  templateUrl: './admin-booking.component.html',
  styleUrls: ['./admin-booking.component.scss']
})
export class AdminBookingComponent implements OnInit, OnChanges {

  @Output() popupWindowOpen: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() currentSection: number = 0
  isPopupVisible = false;
  model: NgbDateStruct | undefined;
  filters: string[] = [];
  searchFilter: string = "";
  bookings: Booking[] = [];
  sortedBookings: Booking[] = [];
  sortOrder: 'asc' | 'desc' = 'asc';

  constructor(private bookingService: BookingService) { }

  ngOnInit(): void {
    let aux = new Date();
    const year = aux.getFullYear();
    const month = String(aux.getMonth() + 1).padStart(2, '0');
    const day = String(aux.getDate()).padStart(2, '0');
    this.searchFilter = `${year}-${month}-${day}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 1) this.getBookings();
  }

  getBookings(): void {
    this.bookingService.getBookings().subscribe((bookings: any) => {
      this.bookings = bookings.map((booking: any) => {
        return Booking.create({
          code_visita: booking.code_visita,
          code: booking.code,
          numVisitor: booking.num_visitor,
          userId: booking.user_id,
          reserveType: booking.reserve_type,
          date: booking.date,
          bookingId: booking.code_global,
          status: booking.status,
          id: booking.id,
        })
      })
      this.sortedBookings = [...this.bookings];
    })
  }

  toggleSortVisitIds(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.code?.toString().localeCompare(b.code));
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.code?.toString().localeCompare(a.code));
      this.sortOrder = 'asc';
    }
  }

  toggleSortBookingIds(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.code?.toString().localeCompare(b.code));
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.code?.toString().localeCompare(a.code));
      this.sortOrder = 'asc';
    }
  }

  toggleSortDates(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
      this.sortOrder = 'asc';
    }
  }

  toggleSortTimes(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => {
        const timeA = new Date(a.date).getHours() * 60 + new Date(a.date).getMinutes();
        const timeB = new Date(b.date).getHours() * 60 + new Date(b.date).getMinutes();
        return timeA - timeB;
      });
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => {
        const timeA = new Date(a.date).getHours() * 60 + new Date(a.date).getMinutes();
        const timeB = new Date(b.date).getHours() * 60 + new Date(b.date).getMinutes();
        return timeB - timeA;
      });
      this.sortOrder = 'asc';
    }
  }

  toggleSortPass(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.reserveType.localeCompare(b.reserveType));
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.reserveType.localeCompare(a.reserveType));
      this.sortOrder = 'asc';
    }
  }

  toggleSortBookings(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.numVisitor - b.numVisitor);
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.numVisitor - a.numVisitor);
      this.sortOrder = 'asc';
    }
  }

  toggleSortUsers(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.userId.localeCompare(b.userId));
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.userId.localeCompare(a.userId));
      this.sortOrder = 'asc';
    }
  }
  toggleSortStatus(): void {
    if (this.sortOrder === 'asc') {
      this.sortedBookings = [...this.bookings].sort((a, b) => a.status.localeCompare(b.status));
      this.sortOrder = 'desc';
    } else {
      this.sortedBookings = [...this.bookings].sort((a, b) => b.status.localeCompare(a.status));
      this.sortOrder = 'asc';
    }
  }

  deleteBooking(bookingId: number, code: string) {
    this.popupWindowOpen.emit([bookingId, code]);
  }

  refreshBookings() {
    this.getBookings();
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  addFilter(filter: string) {
    if (this.filters.indexOf(filter) === -1) this.filters = [...this.filters, filter];
  }

  removeFilter(filter: string) {
    this.filters = this.filters.filter(item => item !== filter);
  }

  onBackdropClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popupContent = document.querySelector('.popup-content');

    if (popupContent && !popupContent.contains(target)) {
      this.closePopup();
    }
  }

  getSelectedDate = (event: any) => this.searchFilter = event


}
