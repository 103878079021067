import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getVisitByDay(body: any) {
    return this.http.post(`${this.apiUrl}/visitas_diarias`, body)
  }

  updateVisita(id:number, body: any) {
    return this.http.patch(`${this.apiUrl}/visita/${id}`, body)
  }

  createVisit(body: any) {
    return this.http.post(`${this.apiUrl}/visita`, body)
  }

  deleteVisit(id: number) {
    return this.http.delete(`${this.apiUrl}/visita/${id}`)
  }

  modifyDay(body: any) {
    return this.http.post(`${this.apiUrl}/modify_day_custom`, body)
  }
}
