interface TemplateCalendarProps {
  readonly id: number;
  readonly template_name: string;
  readonly template_color: string;
  readonly dates: string[];
}

export class TemplateCalendar {
  readonly id: number;
  readonly template_name: string;
  readonly template_colour: string;
  readonly dates: string[];
  private constructor(props: TemplateCalendarProps) {
    this.id = props.id;
    this.template_name = props.template_name;
    this.template_colour = props.template_color;
    this.dates = props.dates;
  }
}


export class TemplatesMonthInfo {
  readonly templates: TemplateCalendar[];
  private constructor(templates: TemplateCalendar[]) {
    this.templates = templates;
  }
  static create(templates: TemplateCalendar[]): TemplatesMonthInfo {
    return new TemplatesMonthInfo(templates);
  }
}

