import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByStatus'
})
export class FilterByStatusPipe implements PipeTransform {

  transform(visits: any[], status: string): any[] {
    if (!visits || !status) return visits || [];
    const now = new Date();

    return visits.filter(visit => {
      const visitDate = new Date(visit.date);
      const isFuture = visitDate > now;
      const visitStatus = visit?.activityStatus?.toLowerCase();

      if (status.toLowerCase() === 'waiting') {
        return isFuture;
      } else if (status.toLowerCase() === 'inprogress') {
        return visitStatus === 'inprogress' && !isFuture;
      }

      return false;
    });
  }


}
