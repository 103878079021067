import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from 'src/app/shared/services';

@Component({
  selector: 'login-failed',
  templateUrl: './failed.component.html',
  styleUrls: ['./failed.component.scss'],
})
export class FailedComponent implements OnInit {
  constructor(
    private msalService: MsalService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.msalService.instance.clearCache();
    this.authService.logout();
  }
}
