import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nextVisits'
})
export class NextVisitsPipe implements PipeTransform {

  transform(visits: any): any {
    let now = new Date();
    return visits.filter((visit: any) => new Date(visit.date) > new Date(now));
  }

}
