<h1 class="p-3 p-xxl-5 mb-0">\\PLANTILLAS</h1>
<div class="d-flex w-100 h-100 justify-content-center gap-5 overflow-hidden pb-5">
    <div class="d-flex flex-column overflow-auto w-50 pe-2">
        <div *ngFor="let template of templates; index as i" class="template-card" (click)="changeActionType(1, i)"
            [ngStyle]="{
            'background-color': this.selectedTemplate == i ? '#0600ff' : '#213948'
        }">
            <div class="number">
                <h4>{{ template?.getId() }}</h4>
                <span class="color" [ngStyle]="{'background-color': template.getTemplateColour()}"></span>
            </div>
            <div class="content">
                <div>
                    <h4>{{ template?.getTemplateName() }}</h4>
                </div>
                <div class="text">{{ template?.getNota() }}</div>
            </div>
        </div>
    </div>
    <div class="w-25">
        <div class="info-card">
            <div>
                <h4 *ngIf="actionType == 0">CREAR PLANTILLA</h4>
                <h4 *ngIf="actionType == 1">INFO PLANTILLA</h4>
                <h4 *ngIf="actionType == 2">EDITAR PLANTILLA</h4>
                <img *ngIf="actionType == 1" class="edit-svg" src="assets/edit.svg" alt="edit"
                    (click)="changeActionType(2, selectedTemplate)">
                <img *ngIf="actionType == 1" class="edit-svg" src="assets/images/btn_close.svg" alt="edit"
                    (click)="deleteTemplate(selectedTemplate)">
                <img *ngIf="actionType == 2" src="assets/images/btn_close.svg" alt="close"
                    (click)="changeActionType(0, -1)">
            </div>
            <form [formGroup]="createTemplateForm" (ngSubmit)="submitCreateTemplate()" autocomplete="off">
                <input type="hidden" formControlName="idPlantilla" name="idPlantilla" id="idPlantilla">
                <div class="container">
                    <div class="input">
                        <p>Nombre Plantilla</p>
                        <input type="text" formControlName="nombrePlantilla" name="nombrePlantilla" id="nombrePlantilla"
                            placeholder="Nombre Plantilla...">
                    </div>
                    <div class="input w_45">
                        <p>Estado</p>
                        <select formControlName="estado" id="estado" name="estado">
                            <option value="Abierto">Abierto</option>
                            <option value="Cerrado">Cerrado</option>
                        </select>
                    </div>
                </div>
                <!-- Horario Mañana -->
                <div class="subtitle">
                    <h5>DÍA</h5>
                    <p>(hasta las 14h)</p>
                </div>
                <div class="container m_0">
                    <div class="input _45">
                        <p>Tipo de visita</p>
                        <select formControlName="tipoVisitaMañana" id="tipoVisitaMañana" name="tipoVisitaMañana">
                            <option value="particular">Pase General</option>
                            <option value="collective">Pase Especial</option>
                        </select>
                    </div>
                    <div class="input">
                        <p>Horario de mañana</p>
                        <div>
                            <input type="time" formControlName="horarioMañanaInicio" id="horarioMañanaInicio"
                                name="horarioMañanaInicio">
                            <span>-</span>
                            <input type="time" formControlName="horarioMañanaFinal" id="horarioMañanaFinal"
                                name="horarioMañanaFinal">
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="input _45">
                        <p>Tiempo entre visitas (minutos)</p>
                        <input type="number" formControlName="tiempoVisitasMañana" id="tiempoVisitasMañana"
                            name="tiempoVisitasMañana" placeholder="En minutos">
                    </div>
                    <div class="input">
                        <div class="divided-info">
                            <p>Personas visita</p>
                            <p>Personas por grupo</p>
                        </div>
                        <div>
                            <input type="number" formControlName="personasVisitaMañanaInicio"
                                id="personasVisitaMañanaInicio" name="personasVisitaMañanaInicio">
                            <span class="no-color">-</span>
                            <input type="number" formControlName="personasVisitaMañanaFinal"
                                id="personasVisitaMañanaFinal" name="personasVisitaMañanaFinal">
                        </div>
                    </div>
                </div>
                <!-- Horario tarde -->
                <div class="subtitle">
                    <h5>TARDE</h5>
                    <p>(De 14h a cierre)</p>
                </div>
                <div class="container m_0">
                    <div class="input">
                        <p>Tipo de visita</p>
                        <select formControlName="tipoVisitaTarde" id="tipoVisitaTarde" name="tipoVisitaTarde">
                            <option value="particular">Pase General</option>
                            <option value="collective">Pase Especial</option>
                        </select>
                    </div>
                    <div class="input">
                        <p>Horario de tarde</p>
                        <div>
                            <input type="time" formControlName="horarioTardeInicio" id="horarioTardeInicio"
                                name="horarioTardeInicio">
                            <span>-</span>
                            <input type="time" formControlName="horarioTardeFinal" id="horarioTardeFinal"
                                name="horarioTardeFinal">
                        </div>
                    </div>
                </div>
                <div class="container m_0">
                    <div class="input _45">
                        <p>Tiempo entre visitas (minutos)</p>
                        <input type="number" formControlName="tiempoVisitasTarde" id="tiempoVisitasTarde"
                            name="tiempoVisitasTarde" placeholder="En minutos">
                    </div>
                    <div class="input">
                        <div class="divided-info">
                            <p>Personas visita</p>
                            <p>Personas por grupo</p>
                        </div>

                        <div>
                            <input type="number" formControlName="personasVisitaTardeInicio"
                                id="personasVisitaTardeInicio" name="personasVisitaTardeInicio">
                            <span class="no-color">-</span>
                            <input type="number" formControlName="personasVisitaTardeFinal"
                                id="personasVisitaTardeFinal" name="personasVisitaTardeFinal">
                        </div>
                    </div>
                </div>
                <!-- Color Asociado -->
                <div class="container size-med m_0">
                    <div class="input">
                        <p>Color asociado</p>
                        <input type="color" class="hidden-input" id="TemplateColor" (change)="colorSelected($event)">
                        <input type="text" formControlName="colorAsociado" id="colorAsociado" name="colorAsociado"
                            (click)="openColor()" value=""
                            [ngStyle]="{'background-color': this.colorBackgroundSelector}">
                    </div>
                </div>
                <!-- Nota -->
                <div class="container m_1">
                    <div class="input">
                        <p>Nota</p>
                        <textarea formControlName="nota" id="nota" name="nota"></textarea>
                    </div>
                </div>
                <!-- Boton -->
                <div *ngIf="actionType == 0 || actionType == 2" class="container">
                    <div class="button-triangle-wrapper w-auto">
                        <button class="button" type="submit" id="confirmar">Confirmar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Popup confirmacion -->
<div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
    'opacity': popupConfirmation ? '1': '0',
    'pointer-events': popupConfirmation ? 'all': 'none'
  }">
    <div class="popup-content">
        <p class="py-2">{{ popupText }}</p>
        <div class="py-2">
            <div (click)="confirmPopup()" class="button-triangle-wrapper w-auto"><button
                    class="button">Confirmar</button></div>
        </div>
    </div>
</div>

<div class="popup-confirmation d-flex justify-content-center align-items-center" [ngStyle]="{
    'opacity': popupConfirmationDelete ? '1': '0',
    'pointer-events': popupConfirmationDelete ? 'all': 'none'
  }">
    <div class="popup-content">
        <p class="py-2">¿Estás seguro que quieres eliminar la plantilla?</p>
        <div class="py-2">
            <div (click)="this.popupConfirmationDelete = false" class="button-triangle-wrapper w-auto"><button
                class="button">Cancelar</button></div>
            <div (click)="confirmDelete()" class="button-triangle-wrapper w-auto"><button
                    class="button">Confirmar</button></div>
        </div>
    </div>
</div>
