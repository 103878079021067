import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPipe } from './user.pipe';
import { BookingPipe } from './booking.pipe';
import { NextVisitsPipe } from 'src/app/shared/pipes/next-visits.pipe';
import { PreviousVisitsPipe } from 'src/app/shared/pipes/previous-visits.pipe';
import { LogPipe } from './log.pipe';
import { FilterByStatusPipe } from './filter-by-status.pipe';
import { DateStylePipe } from './date-style.pipe';
import { DateUTCPipe } from './date-utc.pipe';



@NgModule({
  declarations: [
    UserPipe,
    BookingPipe,
    NextVisitsPipe,
    PreviousVisitsPipe,
    LogPipe,
    FilterByStatusPipe,
    DateStylePipe,
    DateUTCPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UserPipe,
    BookingPipe,
    NextVisitsPipe,
    PreviousVisitsPipe,
    LogPipe,
    FilterByStatusPipe,
    DateStylePipe,
    DateUTCPipe
  ]
})
export class PipesModule { }
