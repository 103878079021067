import { BookingDTO } from 'src/app/shared/dtos';
import { Booking } from 'src/app/shared/models';

export class BookingMapper {
  static fromDto(bookingDto: BookingDTO): Booking {
    return Booking.create({
      code_visita: bookingDto.code_visita,
      code: bookingDto.code,
      numVisitor: bookingDto.num_visitor,
      userId: bookingDto.user_id,
      reserveType: bookingDto.reserve_type,
      date: new Date(bookingDto.date),
      bookingId: "",
      status: "",
      id: 0,
    });
  }

  static toDto(booking: Booking): BookingDTO {
    return {
      code_visita: booking.code_visita,
      code: booking.code,
      num_visitor: booking.numVisitor,
      user_id: booking.userId,
      reserve_type: booking.reserveType,
      date: booking.date.toISOString(),
    };
  }

  static arrayToDto(dailyAvailabilities: Array<Booking>): Array<BookingDTO> {
    return dailyAvailabilities.map((availability) =>
      BookingMapper.toDto(availability),
    );
  }

  static arrayFromDto(
    dailyAvailabilityDTOs: Array<BookingDTO>,
  ): Array<Booking> {
    return dailyAvailabilityDTOs.map((dto) => BookingMapper.fromDto(dto));
  }
}
