export enum BookingType {
  particular = 'particular',
  collective = 'collective',
}

interface BookingProps {
  code_visita: string;
  code: string;
  numVisitor: number;
  userId: string;
  reserveType: BookingType;
  date: Date;
  bookingId: string;
  status: string,
  id: number,
}

// Modelo
export class Booking {
  readonly code_visita: string;
  readonly code: string;
  readonly numVisitor: number;
  readonly userId: string;
  readonly reserveType: BookingType;
  readonly date: Date;
  readonly bookingId: string;
  readonly status: string;
  readonly id: number;

  private constructor(props: BookingProps) {
    this.code_visita = props.code_visita;
    this.code = props.code;
    this.numVisitor = props.numVisitor;
    this.userId = props.userId;
    this.reserveType = props.reserveType;
    this.date = props.date;
    this.bookingId = props.bookingId;
    this.status = props.status;
    this.id = props.id;
  }

  static create(props: BookingProps): Booking {
    return new Booking(props);
  }

  getActiveReservationMessage(): string {
    const formattedDate = this.date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      // timeZone: 'UTC',
    });
    const formattedTime = this.date.toLocaleTimeString('es-ES', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      // timeZone: 'UTC',
    });

    return `Ya tienes una reserva activa para el ${formattedDate} a las ${formattedTime}h. Recuerda que solo es posible tener una por usuario.`;
  }

  getDateFormat() {
    return `${this.date.toLocaleDateString()} ${this.date.getHours()}:${this.date.getMinutes()} h`;
  }
}
