import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  private readonly apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getTemplates() {
    return this.http.get(`${this.apiUrl}/templates`);
  }

  createTemplate(body: any) {
    return this.http.post(`${this.apiUrl}/template`, { body });
  }

  editTemplate(body: any, id: number) {
    return this.http.patch(`${this.apiUrl}/template/${id}`, body);
  }

  deleteTemplate(id: number) {
    return this.http.delete(`${this.apiUrl}/template/${id}`)
  }

  applyTemplateToDays(body: any) {
    return this.http.post(`${this.apiUrl}/apply_template`, body);
  }

}
