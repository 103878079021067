import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(users: User[], filter: string, filters: string[]): any[] {
    if (!users) return [];
    if (!filter && filters.length == 0) return users;
    const lowerCaseFilter = filter.toLocaleLowerCase();


    return users.filter(user => {
      const matchesMainFilter = !lowerCaseFilter ||
        user.id.toLowerCase().includes(lowerCaseFilter) ||
        user.role?.toLowerCase().includes(lowerCaseFilter);
      if (filters.length == 0) return matchesMainFilter;
      const matchesFilters = filters.some(f => { return user.role === f.toLowerCase() });
      return matchesMainFilter && matchesFilters;
    });
  }

}
