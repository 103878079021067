import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateUTC'
})
export class DateUTCPipe implements PipeTransform {

  transform(value: string | Date): string {
    const utcDate = new Date(value);
    const madridDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    
    return `${madridDate.getHours().toString().padStart(2, '0')}:${madridDate.getMinutes().toString().padStart(2, '0')}`;
  }

}
