import { Pipe, PipeTransform } from '@angular/core';
import { Log } from '../models/Log';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {

  transform(logs: Log[], filter: string, filters: string[]): any[] {
    if (!logs) return [];
    // If no general filter and no specific filters, return all logs
    if (!filter && filters.length === 0) return logs;
    filters = filters.map((element: string) => {
      if (element == 'Plantilla') return 'template';
      return element; // Return unchanged elements
    });
    // Map Spanish action types to their corresponding English equivalents
    filters = filters.map((f: string) => f.toLocaleLowerCase());

    const lowerCaseFilter = filter ? filter.toLocaleLowerCase() : '';
    return logs.filter(log => {
      const matchesMainFilter = !lowerCaseFilter ||
        log.getMoment().toLowerCase().includes(lowerCaseFilter) ||
        log.getUserDo().toLowerCase().includes(lowerCaseFilter) ||
        ("Tipo de acción: " + log.getActionDo() + " " + log.getActionType() + ", resultado: " + log.getResultDo())
          .toLowerCase()
          .includes(lowerCaseFilter);
      const matchesFilters = filters.length === 0 || filters.some(f => { return log.getActionDo().toLowerCase() === f || log.getActionType().toLowerCase() === f });
      return matchesMainFilter && matchesFilters;
    });
  }
}
