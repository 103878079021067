import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

@Injectable()
export class ApiAuthInterceptor implements HttpInterceptor {
  constructor(private authService: MsalService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Verifica si la solicitud es para la API discoveryCenter MiG
    if (request.url.startsWith(environment.apiUrl)) {
      const currentAccount = this.authService.instance.getActiveAccount();

      // Si no hay cuenta activa, redirigir a login o continuar con la solicitud original
      if (!currentAccount) {
        this.authService.logout();
        return next.handle(request);
      }

      const requestToken = {
        scopes: environment.apiConfig.scopes,
        account: currentAccount,
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 7200, // 2 hours * 60 minutes * 60 seconds = 7200 seconds
      };

      return from(
        this.authService.instance.acquireTokenSilent(requestToken),
      ).pipe(
        switchMap((response) => {
          const token = response?.idToken;

          // Si hay un token válido, clona la solicitud y añade el Bearer
          if (token) {
            const authReq = request.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
            return next.handle(authReq);
          }
          // Si no hay token, continúa con la solicitud original
          return next.handle(request);
        }),
        catchError((error) => {
          // Aquí se maneja el error SOLO de la adquisición del token
          console.error('Algún error ha habido:', error);
          // Si el error ocurre durante la adquisición del token, cierra la sesión
          if (error instanceof InteractionRequiredAuthError) {
            console.error(
              'Error en la adquisición del token, cerrando sesión:',
              error,
            );
            this.authService.logout();
          }

          // Continua con la solicitud original si no es un problema de token
          return next.handle(request);
        }),
      );
    }

    // Si no es para la API externa, sigue con la solicitud original
    return next.handle(request);
  }
}
