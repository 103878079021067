<h1 class="p_5 pb-0">\\HISTÓRICO</h1>
<div class="table px-5 m_tables h-100 pb-5">
  <div class="top-elements d-flex flex-row-reverse gap-4">
    <button class="filter btn button-border-left font-Lato-regular text-xxs pe-3" (click)="openPopup()">
      <img src="assets/hamburger-icon.svg" alt="closeButton"> Filtros
    </button>
    <!-- Pop-up -->
    <div class="popup" *ngIf="isPopupVisible" (click)="onBackdropClick($event)">
      <div class="popup-content">
        <span class="close" (click)="closePopup()">&times;</span>
        <p class="p-filtrado">FILTRADO</p>
        <div class="popup-content-button">
          <button class="popup-button" (click)="addFilter('Crear')">Crear</button>
          <button class="popup-button" (click)="addFilter('Borrar')">Borrar</button>
          <button class="popup-button" (click)="addFilter('Actualizar')">Actualizar</button>
          <button class="popup-button" (click)="addFilter('Plantilla')">Plantilla </button>
          <button class="popup-button" (click)="addFilter('Reserva')">Reserva</button>
        </div>
      </div>
    </div>
    <input class=".search-bar" type="text" name="search" id="search" placeholder="Búsqueda..."
      [(ngModel)]="searchFilter">
    <!-- Filtros -->
    <div class="filters" *ngFor="let filtro of this.filters">
      {{ filtro }}
      <img src="assets/images/btn_close.svg" alt="closeButon" (click)="removeFilter(filtro)">
    </div>
  </div>
  <div class="table-content mt-4 mb-5">
    <div class="d-flex table_header">
      <div class="header col col-2">
        Fecha
        <img (click)="toggleSortDate()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-3">
        Usuarios
        <img (click)="toggleSortUsers()" src="assets/filter-icon.svg" alt="filter">
      </div>
      <div class="header col col-7 text-start">
        Cambios
        <img (click)="toggleSortChanges()" src="assets/filter-icon.svg" alt="filter">
      </div>
    </div>
    <div class="table_body">
      <div *ngFor="let log of this.sortedLogs | log: searchFilter : filters"
        class="d-flex align-items-center table_row">
        <span class="col col-2">{{ log.getMoment() | date: 'yyyy-MM-dd hh:mm' }}</span>
        <span class="col col-3">{{ log.getUserDo() }}</span>
        <span class="col col-7 text-start">
          El usuario <b>{{ log.getUserDo() }}</b> ha <b>{{ log.getActionDoHumanReadable() }}</b> el/la {{
          log.getActionType() }}
          <b>{{log.getTargetDo()}}</b> {{log.getResultHumanReadable()}}
        </span>
      </div>
    </div>
  </div>
