import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit, OnChanges {

  @Input() currentSection: number = 0
  users: User[] = [];
  sortedUsers: User[] = [];
  isPopupVisible = false;
  model: NgbDateStruct | undefined;
  searchFilter: string = "";
  filters: string[] = [];
  sortOrder: 'asc' | 'desc' = 'asc';
  editUserAction: string = "";
  popupConfirmation: boolean = false
  selectedUserDelete: number = 0;
  roles: any = {
    client: "Usuario",
    admin: "Admin",
    superadmin: "SuperAdmin",
    suspended: "Suspendido",
    blocked: "Bloqueado",
    control: "Control"
  };

  constructor(
    private usersService: UsersService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentSection"] && changes["currentSection"].currentValue == 3) this.getUsers();
  }

  getUsers() {
    this.usersService.getUsers().subscribe((users: any) => {
      this.users = users.map((user: any) => {
        return User.create({
          id: user.user_id,
          role: user.rol,
        })
      })
      this.sortedUsers = [...this.users];
    })
  }

  editUser(user: string) {
    let element = document.getElementById(user) as HTMLSelectElement;
    if (this.editUserAction == "") {
      this.editUserAction = user;
      document.getElementById(user + "edit")?.setAttribute("src", "assets/confirm-icon.svg")
    } else {
      document.getElementById(user + "edit")?.setAttribute("src", "assets/edit.svg")

      let body = {
        id: user,
        rol: element.options[element.selectedIndex].value
      }
      this.usersService.updateUser(body, user).subscribe(
        (users: any) => {
          this.showSuccessToast("Rol del usuario cambiado exitosamente")
        },
        (error) => {
          console.error(error);
          this.showErrorToast(error)
        }
      )
      this.editUserAction = "";
    }
  }

  cancelEditUser(user: string) {
    this.editUserAction = "";
    document.getElementById(user + "edit")?.setAttribute("src", "assets/edit.svg")
  }

  deleteUser(user: number) {
    this.selectedUserDelete = user;
    this.popupConfirmation = true;
  }

  confirmDeleteUser() {
    this.usersService.deleteUser(this.selectedUserDelete).subscribe(
      (users: any) => {
        this.showSuccessToast("Usuario eliminado exitosamente")
        this.getUsers()
      },
      (error) => {
        console.error(error);
        this.showErrorToast(error)
      }
    );
    this.popupConfirmation = false;
  }

  addFilter(filter: string) {
    if (this.filters.indexOf(filter) === -1) this.filters = [...this.filters, filter];
  }

  removeFilter(filter: string) {
    this.filters = this.filters.filter(item => item !== filter);
  }

  openPopup() {
    this.isPopupVisible = true;
  }

  closePopup() {
    this.isPopupVisible = false;
  }

  onBackdropClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popupContent = document.querySelector('.popup-content');

    if (popupContent && !popupContent.contains(target)) {
      this.closePopup();
    }
  }

  toggleSortUsers(): void {
    if (this.sortOrder === 'asc') {
      this.sortedUsers = [...this.users].sort((a, b) => a.id.localeCompare(b.id));
      this.sortOrder = 'desc';
    } else {
      this.sortedUsers = [...this.users].sort((a, b) => b.id.localeCompare(a.id));
      this.sortOrder = 'asc';
    }
  }

  toggleSortRole(): void {
    if (this.sortOrder === 'asc') {
      this.sortedUsers = [...this.users].sort((a, b) => a.role.localeCompare(b.role));
      this.sortOrder = 'desc';
    } else {
      this.sortedUsers = [...this.users].sort((a, b) => b.role.localeCompare(a.role));
      this.sortOrder = 'asc';
    }
  }

  showSuccessToast(message: string) {
    this.toastService.success("", message, {
      toastClass: 'ngx-toastr toast-success',
      progressBar: true,
      timeOut: 3000
    });
  }

  showErrorToast(error: any) {
    this.toastService.error(error.error, 'Error', {
      toastClass: 'ngx-toastr toast-error',
      progressBar: true,
      timeOut: 3000
    });
  }
}
