import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateStyle'
})
export class DateStylePipe implements PipeTransform {


  transform(date: any, templatesInfo: any, show: boolean): string {
    if (!show) return "color: #FFFFFF;";
    let dateString = date.year + "-" + date.month.toString().padStart(2, '0') + "-" + date.day.toString().padStart(2, '0');
    let color = "color: #9e9e9e;";
    // We find the date in the templates
    if (templatesInfo) {
      let template = templatesInfo.templates.find((template: any) => { return template.dates.includes(dateString) });
      if (template) color = "color: " + template.template_colour + ";";
    }
    return color
  }

}
